<template>
  <div>
    <titleLink title="天气" :onClick="onClickBack"></titleLink>
    <div style="padding:.3rem .2rem 0 .2rem;height:100vh;overflow-y:scroll">
      <card style="margin-bottom:3rem">
        <template #content>
          <iframe scrolling="no" src="https://tianqiapi.com/api.php?style=tw&skin=pitaya" 
            frameborder="0" width="300" height="500" allowtransparency="true"></iframe>
        </template>
      </card>
    </div>
  </div>
</template>

<script>
import titleLink from "@/views/components/TitleLink"
import card from "@/views/components/Card";
export default {
  components: {
    titleLink, card
  },
  methods: {
    onClickBack() {
      this.$router.push("/home");
    },
  }
}
</script>

<style>

</style>